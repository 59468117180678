<template>
  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <sub-title>출석부</sub-title>
        <div class="cul_main">
          <div class="box" style="margin: 0 auto">
            <div class="date">
              {{ month }} 월 출석부
            </div>
            <p style="font-size: 12px;padding: 15px 0;color: floralwhite;width: 100%;text-align: center;background-color: #000000;margin-bottom: 5px">
              00:00 ~ 24:00 하루 누적
              <span style="color: #ffffff">{{ $store.state.userInfo.rank.culcheckrechargeamount|comma }}</span> 이상 자동출석</p>
            <div class="week">일</div>
            <div class="week">월</div>
            <div class="week">화</div>
            <div class="week">수</div>
            <div class="week">목</div>
            <div class="week">금</div>
            <div class="week">토</div>
            <div class="day" v-for="(item,index) in culchekinfo.daysinfo">
              <div class="daynumber"><span v-if="item.day > 0">{{ item.day }}</span></div>
              <!--                            <i class="fa fa-star ck" v-if="item.isculckeck"></i>-->
              <img src="../../assets/images/logo/balam/logo03.png" v-if="item.isculckeck" alt="">
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>
  </div>

</template>

<script>
import TopbarComp from "../../components/TopbarComp";
import LeftBarComp from "../../components/LeftBarComp";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import RightBarComp from "../../components/RightBarComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import FootComp from "../../components/FootComp";
import sportsConst from "../../common/sportsConst";
import SubTitle from "../../components/SubTitle";
import {getCulcheck} from "../../network/userRequest";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
import {postionMixin} from "../../common/mixin";

export default {
  mixins: [postionMixin],
  components: {
    SportsBetCartComp,
    RightButtonsComp,
    UserInfoComp,
    SubTitle, FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp
  },
  data() {
    return {
      sportsConst,
      culchekinfo: {},
      month: 0,
      position: "출석부",

    }
  },
  mounted() {

  },
  methods: {},
  created() {
    this.month = this.$moment().month() + 1
    getCulcheck().then(res => {
      if (res.data.success) {
        let data = res.data.data;
        let daysinfo = []
        let lastDay = this.$moment().daysInMonth();
        let startWeek = this.$moment().startOf('month').weekday()
        let daynumber = 1;
        for (let i = 0; i < 42; i++) {
          let d = {}
          this.$set(d, 'isculckeck', false)
          if (i < startWeek) {
            this.$set(d, 'day', 0)
          } else if (i >= (lastDay + startWeek)) {
            this.$set(d, 'day', 0)
          } else {
            this.$set(d, 'day', daynumber)
            daynumber++

            data.map(resdata => {
              if (resdata.sday == d.day) this.$set(d, 'isculckeck', true)
            })
          }


          daysinfo.push(d)
        }
        this.$set(this.culchekinfo, 'startWeek', this.$moment().startOf('month').weekday())
        this.$set(this.culchekinfo, 'daysinfo', daysinfo)
        console.log(this.culchekinfo)
      }
    })
  }
}
</script>

<style scoped>
@import url("../../assets/css/base.css");
@import url("../../assets/css/common.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/media.css");
@import url("../../assets/css/sports.css");

.cul_main {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.box {
  --width: 750px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: #15161b;
  --background: url("../../assets/images/bg/kisa/culcheck_bg.jpg") top no-repeat;
}

.box .date {
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff9ff;
  padding: 5px 0;
}

.box .week {
  width: 14%;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background-color: var(--tableThBg);
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}

.box .day {
  width: 14%;
  height: 99px;
  text-align: center;
  font-size: 12px;
  color: #00ca18;
  position: relative;
  border: 1px solid #353539;
  margin-top: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box .day img {
  width: 40px;
}


.box .day2 {
  border: 0 !important;
}

.day .daynumber {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 12px;
  top: 12px;
  color: #fff2e3;
  font-weight: bold;
  font-size: 14px;

}

.day .ck {
  font-size: 4.0vmax;
  color: #ffd700;
}

@media screen and (max-width: 1024px) {
  .box .day {
    height: 52px !important;
  }

  .box .day img {
     width: 30px;
  }

  .day .ck {
    font-size: 23px !important;
  }

  .day .daynumber {
    width: 15px;
    height: 15px;
    font-size: 11px;
    right: 4px !important;
    top: 4px !important;


  }

}


</style>